// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "OurStoryMain-module--aboutMainCircleBlurBlue--egtDS";
export var authorLinks = "OurStoryMain-module--authorLinks--SdEiL";
export var authorName = "OurStoryMain-module--authorName--GWZYI";
export var bHeading = "OurStoryMain-module--bHeading--Tutkn";
export var bannerMargin = "OurStoryMain-module--bannerMargin--BCrJ3";
export var blogImageContainer = "OurStoryMain-module--blogImageContainer--NJxUu";
export var buttonMainClass = "OurStoryMain-module--buttonMainClass--XtM07";
export var caseChallengeImage = "OurStoryMain-module--caseChallengeImage--pDThI";
export var caseTestimonialImage = "OurStoryMain-module--caseTestimonialImage--RrNoC";
export var cloudReachImage = "OurStoryMain-module--cloudReachImage--z40fQ";
export var cloudReachSection = "OurStoryMain-module--cloudReachSection--XydT3";
export var cloudReachText = "OurStoryMain-module--cloudReachText--96EV5";
export var contactButton = "OurStoryMain-module--contactButton--rOaWi";
export var coreValue = "OurStoryMain-module--coreValue--wWf0u";
export var coreValueColumbLeft = "OurStoryMain-module--coreValueColumbLeft--zRzdM";
export var coreValueColumbRight = "OurStoryMain-module--coreValueColumbRight--B28wN";
export var coreValueItem = "OurStoryMain-module--coreValueItem--GwFAN";
export var coreValueItemBox = "OurStoryMain-module--coreValueItemBox--EDf7K";
export var coreValuesHeading = "OurStoryMain-module--coreValuesHeading--6evNe";
export var description = "OurStoryMain-module--description--VeKit";
export var development = "OurStoryMain-module--development--ODTaa";
export var gdnPromotePic = "OurStoryMain-module--gdnPromotePic--L+eDQ";
export var greenColor = "OurStoryMain-module--greenColor--9rmt4";
export var head = "OurStoryMain-module--head--awfAr";
export var heroBackgroundCircleLeft = "OurStoryMain-module--heroBackgroundCircleLeft--kI9Ie";
export var heroBackgroundCircleLeftStory = "OurStoryMain-module--heroBackgroundCircleLeftStory--DIN3N";
export var heroBackgroundCircleLeftStorySecond = "OurStoryMain-module--heroBackgroundCircleLeftStorySecond--RkK17";
export var heroBackgroundInnerCircleLeft = "OurStoryMain-module--heroBackgroundInnerCircleLeft--ZTqL4";
export var heroBackgroundInnerCircleLeftSecond = "OurStoryMain-module--heroBackgroundInnerCircleLeftSecond--17W9w";
export var heroWhiteBlurCirce = "OurStoryMain-module--heroWhiteBlurCirce--ZaSsR";
export var inNumbersImage = "OurStoryMain-module--inNumbersImage--2FcI2";
export var inNumbersItem = "OurStoryMain-module--inNumbersItem--kMgzz";
export var keyTopicsImage = "OurStoryMain-module--keyTopicsImage--5HqYE";
export var knowUs = "OurStoryMain-module--knowUs--mrWkE";
export var knowUsHeading = "OurStoryMain-module--knowUsHeading--gDAJ+";
export var knowUsImage = "OurStoryMain-module--knowUsImage--ME7et";
export var knowUsImageDiv = "OurStoryMain-module--knowUsImageDiv--K5rkn";
export var knowUsOurStory = "OurStoryMain-module--knowUsOurStory--0qQ6t";
export var knowUsOurStoryText = "OurStoryMain-module--knowUsOurStoryText--stoXM";
export var knowUsText = "OurStoryMain-module--knowUsText---IqNz";
export var mobSliderDiv = "OurStoryMain-module--mobSliderDiv--Lcw56";
export var ourStory = "OurStoryMain-module--ourStory--Gjer5";
export var ourStoryDescPara = "OurStoryMain-module--ourStoryDescPara--Ap51a";
export var ourStoryImg = "OurStoryMain-module--ourStoryImg--e-00B";
export var ourStoryImgDiv = "OurStoryMain-module--ourStoryImgDiv--EP57V";
export var ourStoryText = "OurStoryMain-module--ourStoryText--XnuZB";
export var ourStoryTwoColumn = "OurStoryMain-module--ourStoryTwoColumn--mdBDz";
export var ourTeamHeader = "OurStoryMain-module--ourTeamHeader--FTit5";
export var ourTeamSection = "OurStoryMain-module--ourTeamSection--gaRZM";
export var singleBenefitTextBox = "OurStoryMain-module--singleBenefitTextBox--b+yrm";
export var singleCaseChallenge = "OurStoryMain-module--singleCaseChallenge--jTZHQ";
export var singleCaseContentBlock = "OurStoryMain-module--singleCaseContentBlock--Cb0vf";
export var singleCaseImageBox1 = "OurStoryMain-module--singleCaseImageBox1--IXfJB";
export var singleCaseSolution = "OurStoryMain-module--singleCaseSolution--ZPLnX";
export var singleCaseTestimonial = "OurStoryMain-module--singleCaseTestimonial--qB2bZ";
export var singleCaseTextBox = "OurStoryMain-module--singleCaseTextBox--LuTf6";
export var sliderButtonContainer = "OurStoryMain-module--sliderButtonContainer--8auMV";
export var sliderDescription = "OurStoryMain-module--sliderDescription--qHnF+";
export var sliderItem = "OurStoryMain-module--sliderItem--psyNN";
export var sliderWrapper = "OurStoryMain-module--sliderWrapper--EjR1a";
export var storyPromotePic = "OurStoryMain-module--storyPromotePic--JCTKI";
export var storyPromotePicSecond = "OurStoryMain-module--storyPromotePicSecond--hs17E";
export var teamSliderItem = "OurStoryMain-module--teamSliderItem---8TRy";
export var testimonialContent = "OurStoryMain-module--testimonialContent--tq6QY";
export var topHeading = "OurStoryMain-module--topHeading--yKaN9";
export var yourBenefits = "OurStoryMain-module--yourBenefits--y8bJE";