import ourStoryImg6 from "../../images/ourStoryImg6.png";
import ourStoryImg3 from "../../images/ourStory-Img.png";
import translate from "../../i18n/translate";

export const coreValues = [
  {
    count: "01",
    heading: translate("ourstory-cloudValueHeading1"),
    description: translate("ourstory-cloudValueDesc1"),
  },
  {
    count: "02",
    heading: translate("ourstory-cloudValueHeading2"),
    description: translate("ourstory-cloudValueDesc2"),
  },
  {
    count: "03",
    heading: translate("ourstory-cloudValueHeading3"),
    description: translate("ourstory-cloudValueDesc3"),
  },
  {
    count: "04",
    heading: translate("ourstory-cloudValueHeading4"),
    description: translate("ourstory-cloudValueDesc4"),
  },
  {
    count: "05",
    heading: translate("ourstory-cloudValueHeading5"),
    description: translate("ourstory-cloudValueDesc5"),
  },
  {
    count: "06",
    heading: translate("ourstory-cloudValueHeading6"),
    description: translate("ourstory-cloudValueDesc6"),
  },
];

export const OurStoryMainData = {
  heading1: translate("ourstory-beUniqueHeading"),
  paragraph1: translate("ourstory-beUniqueDescription"),
  heading2: translate("ourstory-behindSceneHeading"),
  paragraph2: translate("ourstory-behindSceneDescription"),
};

export const TwoColumnBannerData = {
  heading: OurStoryMainData.heading1,
  details: OurStoryMainData.paragraph1,
  img: ourStoryImg6,
  alt: "Steep cliff separating two beaches to showcase different cloud native approach taken by globaldatanet",
};

export const TwoColumnBannerDataSecond = {
  heading: OurStoryMainData.heading2,
  description: OurStoryMainData.paragraph2,
  img: ourStoryImg3,
  alt: "Boat driving on a river winding through a thick forest for out of the box creative cloud approach and finding our own way",
};
