// extracted by mini-css-extract-plugin
export var ArrowContainer = "SharedSlider-module--ArrowContainer--k-qFF";
export var authorGithub = "SharedSlider-module--authorGithub--mATQ-";
export var authorLinkedIn = "SharedSlider-module--authorLinkedIn---LBEM";
export var authorName = "SharedSlider-module--authorName--EJR02";
export var blogImageContainer = "SharedSlider-module--blogImageContainer--+zijZ";
export var caseChallengeImage = "SharedSlider-module--caseChallengeImage--w94wm";
export var caseStudiesButtonsContainer = "SharedSlider-module--caseStudiesButtonsContainer--3NmFx";
export var caseStudiesDescription = "SharedSlider-module--caseStudiesDescription--2sQg+";
export var caseTestimonialImage = "SharedSlider-module--caseTestimonialImage--jDvYV";
export var cloudInsight = "SharedSlider-module--cloudInsight--JrOe2";
export var cloudInsightHeader = "SharedSlider-module--cloudInsightHeader--+kT+2";
export var contactButton = "SharedSlider-module--contactButton--Lzfii";
export var coreValue = "SharedSlider-module--coreValue--txDBw";
export var coreValueColumbLeft = "SharedSlider-module--coreValueColumbLeft--DdG+E";
export var coreValueColumbRight = "SharedSlider-module--coreValueColumbRight--PKy0+";
export var coreValueItem = "SharedSlider-module--coreValueItem--v3trz";
export var coreValueItemBox = "SharedSlider-module--coreValueItemBox--i2LhW";
export var designation = "SharedSlider-module--designation--7x6zQ";
export var gdnPromotePic = "SharedSlider-module--gdnPromotePic--kfCz5";
export var greenColor = "SharedSlider-module--greenColor--SbyXG";
export var heroBackgroundCircleLeft = "SharedSlider-module--heroBackgroundCircleLeft--Z4JHA";
export var heroBackgroundInnerCircleLeft = "SharedSlider-module--heroBackgroundInnerCircleLeft--aZ-I8";
export var heroBackgroundInnerCircleOverflowText = "SharedSlider-module--heroBackgroundInnerCircleOverflowText--zHSHl";
export var heroWhiteBlurCirce = "SharedSlider-module--heroWhiteBlurCirce--qIfAu";
export var inNumbersImage = "SharedSlider-module--inNumbersImage--6T3uQ";
export var inNumbersItem = "SharedSlider-module--inNumbersItem--fG8j1";
export var keyTopicsImage = "SharedSlider-module--keyTopicsImage--jXGkh";
export var knowUs = "SharedSlider-module--knowUs--ZuLEH";
export var knowUsImage = "SharedSlider-module--knowUsImage--HQXoi";
export var knowUsText = "SharedSlider-module--knowUsText--nE0Vs";
export var mobSliderDiv = "SharedSlider-module--mobSliderDiv--Tb8fr";
export var ourStory = "SharedSlider-module--ourStory--NsplT";
export var singleBenefitTextBox = "SharedSlider-module--singleBenefitTextBox--5WWkt";
export var singleCaseChallenge = "SharedSlider-module--singleCaseChallenge--RGyxU";
export var singleCaseContentBlock = "SharedSlider-module--singleCaseContentBlock--ZGSvs";
export var singleCaseImageBox1 = "SharedSlider-module--singleCaseImageBox1--FK0QT";
export var singleCaseImageBox2 = "SharedSlider-module--singleCaseImageBox2--nwLhJ";
export var singleCaseSolution = "SharedSlider-module--singleCaseSolution--NlYyz";
export var singleCaseTestimonial = "SharedSlider-module--singleCaseTestimonial--m199A";
export var singleCaseTestimonialInner = "SharedSlider-module--singleCaseTestimonialInner--e2rI9";
export var singleCaseTextBox = "SharedSlider-module--singleCaseTextBox--ms3SX";
export var sliderDate = "SharedSlider-module--sliderDate--zamQI";
export var sliderItem = "SharedSlider-module--sliderItem--i1U3-";
export var testimonialBody = "SharedSlider-module--testimonialBody--4IaXN";
export var testimonialContent = "SharedSlider-module--testimonialContent--Ye-6h";
export var testimonialName = "SharedSlider-module--testimonialName--oYxmB";
export var yourBenefits = "SharedSlider-module--yourBenefits--HU1Cy";
export var yourBenefitsIcon = "SharedSlider-module--yourBenefitsIcon--XFDzr";
export var yourBenefitsInner = "SharedSlider-module--yourBenefitsInner--MD-1q";
export var yourBenefitsItem = "SharedSlider-module--yourBenefitsItem--lq6YD";