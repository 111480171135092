import React, { FC } from "react";
import { Slide } from "pure-react-carousel";
import { UseScrollPosition } from "../../hooks/UseScrollPosition";
import { Link } from "gatsby";
import buttonRight from "../../../images/buttonArrowRight.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment";
import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

type Props = {
  sliderContainerClassName: string;
  sliderImage: any;
  sliderIndex: number;
  sliderDescriptionClassName: string;
  sliderLinksClassName?: string;
  sliderTitle: string;
  sliderDescription?: string;
  publishedDate?: string;
  link: string;
  path: string;
  readMore?: boolean;
  isAnimated?: boolean;
  haveLinks?: boolean;
  linkedIn?: string;
  github?: string;
  alt: string;
};

const SharedSlider: FC<Props> = ({
  sliderContainerClassName,
  sliderImage,
  sliderIndex,
  sliderDescriptionClassName,
  sliderLinksClassName,
  sliderTitle,
  sliderDescription,
  publishedDate,
  link,
  path,
  readMore,
  isAnimated,
  haveLinks,
  linkedIn,
  github,
  alt,
}) => {
  const scrollPosition = UseScrollPosition();

  return (
    <div
      style={{
        minHeight: "700px",
        transform: isAnimated
          ? `translateY(${
              scrollPosition > 0.52
                ? 10 - 0.5 * sliderIndex
                : 40 * sliderIndex * 0.7
            }px)`
          : "none",
        transition: "ease-out transform 2s",
        width: "130%",
        minWidth: "280px",
        height: "100%",
      }}
      className={sliderContainerClassName}
      key={sliderIndex}
    >
      <div id={sliderTitle}>
        <Slide
          index={sliderIndex}
          style={{
            width: "100%",
            height: "361px",
          }}
        >
          <Link to={`${path}${pathWithoutSpace(link || "#")}`}>
            <GatsbyImage image={sliderImage} alt={alt || "Shared Slider"} />
          </Link>
        </Slide>
        <div className={sliderDescriptionClassName}>
          <Link to={`${path}${pathWithoutSpace(link || "#")}`}>
            <div style={{ width: "100%" }}>
              <p
                style={{
                  color: "#00ecbd",
                  width: "130px",
                }}
              >
                {publishedDate
                  ? moment(publishedDate).format("MMM Do YYYY")
                  : null}
              </p>
              <h5>{sliderTitle}</h5>
            </div>
            <p>{sliderDescription}</p>
            {readMore ? (
              <div style={{ textAlign: "left" }}>
                <span style={{ color: "#00ecbd", fontFamily: "Poppins" }}>
                  Read more
                </span>
                &nbsp;&nbsp;
                <img src={buttonRight} alt="" />
              </div>
            ) : null}
          </Link>
          {haveLinks && (
            <div className={sliderLinksClassName}>
              {github && (
                <a href={github} target="_blank" rel="noreferrer">
                  <FaGithub color="#ffff" size={20} />
                </a>
              )}
              {linkedIn && (
                <a href={linkedIn} target="_blank" rel="noreferrer">
                  <FaLinkedinIn color="#ffff" size={22} />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SharedSlider;
