import React, { FC } from "react";
import * as styles from "./OurStoryMain.module.scss";
import tableDesktop from "../../images/ourstoryMainSquare.png";
import tableDesktopMobile from "../../images/mobile-images/ourstoryMainSquare.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import ourStoryImg2 from "../../images/ourStoryImg2.png";
import ourStoryImg4 from "../../images/ourStoryImg4.png";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { CarouselProvider, Slider } from "pure-react-carousel";
import CaseStudiesSliderButtons from "../HomeUiComponents/CaseStudiesMain/Slider/CaseStudiesSliderButtons";
import SharedSlider from "../../components/Shared/SharedCarousel/SharedSlider";
import SharedSliderMobile from "../../components/Shared/SharedCarousel/SharedSliderMobile";
import { graphql, StaticQuery } from "gatsby";
import { authorSorter } from "../Shared/HelperFunctions/AuthorSortHelper";
import { UseImageUrl } from "../hooks/UseImageUrl";
import {
  coreValues,
  TwoColumnBannerData,
  TwoColumnBannerDataSecond,
} from "./OurStoryMain.helper";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import SectionWrapper from "../Shared/SectionWrapper/SectionWrapper";
import translate from "../../i18n/translate";

const OurStoryMain: FC = () => {
  const width = UseWindowSize();
  return (
    <div className={styles.ourStory}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("ourstory-title")}
          description={translate("ourstory-description")}
          homeBackground={tableDesktopMobile}
          alt="Our Story"
        />
      ) : (
        <HeroBanner
          heading={translate("ourstory-title")}
          description={translate("ourstory-description")}
          homeBackground={tableDesktop}
        />
      )}

      <InnerWrapper>
        <div className={styles.bannerMargin}>
          <SectionWrapper>
            <TwoColumnBanner data={TwoColumnBannerData} ourStory />
          </SectionWrapper>
        </div>

        <div className={styles.ourTeamSection}>
          <div className={styles.ourTeamHeader}>
            <h2>
              <span>.</span>
              {translate("ourstory-ourTeam")}
            </h2>
          </div>
          <div className={styles.sliderWrapper}>
            <StaticQuery
              query={query}
              render={data => {
                return width.width <= 576 ? (
                  <SharedSliderMobile
                    data={data?.allSanityAuthor.edges}
                    path={"/our-team/"}
                    haveLinks
                    imagePath={"image"}
                    rawImagePath={"_rawImage"}
                  />
                ) : (
                  <CarouselProvider
                    className={
                      "story_carousel__container carousel__container our_team_slider"
                    }
                    naturalSlideWidth={320}
                    naturalSlideHeight={125}
                    totalSlides={data?.allSanityAuthor?.edges?.length}
                    visibleSlides={
                      width.width <= 770
                        ? 1
                        : width.width < 1200
                        ? 2
                        : width.width > 1500
                        ? 4
                        : width.width < 1500
                        ? 3
                        : 1
                    }
                    currentSlide={0}
                    infinite
                  >
                    <CaseStudiesSliderButtons
                      sliderButtonsClassName={styles.sliderButtonContainer}
                    />
                    <Slider>
                      {data?.allSanityAuthor.edges
                        ?.sort(authorSorter)
                        .map((item: any, index: number) => {
                          UseImageUrl(item?.node.image, item?.node._rawImage);
                          return (
                            <SharedSlider
                              key={index}
                              sliderContainerClassName={styles.teamSliderItem}
                              sliderLinksClassName={styles.authorLinks}
                              sliderIndex={index}
                              sliderImage={
                                item.node.image.asset.gatsbyImageData
                              }
                              sliderDescriptionClassName={
                                styles.sliderDescription
                              }
                              sliderTitle={item?.node?.name}
                              sliderDescription={item?.node?.position}
                              link={item?.node?.name}
                              path={"/our-team/"}
                              haveLinks
                              linkedIn={item?.node?.linkedin}
                              github={item?.node?.github}
                              alt={item?.node?.alt}
                            />
                          );
                        })}
                    </Slider>
                  </CarouselProvider>
                );
              }}
            />
          </div>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
        </div>

        <div>
          <div className={styles.singleCaseContentBlock}>
            <div className={styles.singleCaseSolution}>
              <div className={styles.singleCaseTextBox}>
                <div>
                  <p>{translate("ourstory-joinOurTeamHeading")}</p>
                  <h2 style={{ marginBottom: "0px" }}>
                    {translate("ourstory-joinOurTeamDescription1")}
                  </h2>
                  <h2 style={{ marginTop: "0px" }}>
                    {translate("ourstory-joinOurTeamDescription2")}
                  </h2>
                  <CustomButton
                    text={translate("ourstory-searchCareer")}
                    image={arrowRightWhite}
                    linkPath={"/careers"}
                  />
                </div>
                <CircleBlur
                  initialScale={0.1}
                  animateScale={1}
                  initialOpacity={0}
                  animateOpacity={0.85}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroWhiteBlurCirce}
                />
              </div>
              <div className={styles.singleCaseImageBox1}>
                <img
                  src={ourStoryImg2}
                  alt="Rough rocky beach creating visually appealing scenery of the ocean in line with the shore to show greatness globaldatanet creates"
                />
              </div>
            </div>
            <div className={styles.ourStoryTwoColumn}>
              <TwoColumnBanner
                data={TwoColumnBannerDataSecond}
                ourStorySecond
                ourStory
              />
            </div>
            <div className={styles.coreValue}>
              <div className={styles.coreValueColumbRight}>
                <h4 className={styles.coreValuesHeading}>
                  <span>.</span>
                  {translate("ourstory-cloudValue")}
                  <br></br>
                  {translate("ourstory-cloudValueDescription")}
                </h4>
                <div className={styles.coreValueItemBox}>
                  {coreValues?.map((item, index) => {
                    return (
                      <div className={styles.coreValueItem} key={index}>
                        <h5>{item?.count}</h5>
                        <p className={styles.greenColor}>{item?.heading}</p>
                        <p className={styles.description}>
                          {item?.description}{" "}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.15}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />
                <CircleOutline
                  isBlurVisible={true}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundCircleLeft}
                />
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.15}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeft}
                />
              </div>
              <div className={styles.coreValueColumbLeft}>
                <img
                  src={ourStoryImg4}
                  alt="Settlements on two sides of a river with structured ports showing the clear foundation globaldatanet is built on"
                  className={styles.keyTopicsImage}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.ourTeamSection}>
          <div className={styles.ourTeamHeader}>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <h2>
              <span>.</span>
              {translate("ourstory-newsRoom")}
            </h2>
          </div>

          <div className={styles.sliderWrapper}>
            <StaticQuery
              query={query}
              render={data =>
                width.width <= 770 ? (
                  <SharedSliderMobile
                    data={data?.newsBlogs?.edges}
                    path={"/news/"}
                    imagePath={"mainImage"}
                    haveReadMore
                    haveDate
                    rawImagePath={"_rawMainImage"}
                  />
                ) : (
                  <CarouselProvider
                    className={
                      "story_carousel__container carousel__container our_team_slider"
                    }
                    naturalSlideWidth={120}
                    naturalSlideHeight={125}
                    totalSlides={data?.newsBlogs?.edges?.length}
                    visibleSlides={
                      width.width <= 770
                        ? 1
                        : width.width <= 1450
                        ? 2
                        : width.width > 1450
                        ? 3
                        : 2
                    }
                    currentSlide={0}
                    infinite
                  >
                    <CaseStudiesSliderButtons
                      sliderButtonsClassName={styles.sliderButtonContainer}
                    />
                    <Slider>
                      {data?.newsBlogs?.edges?.map(
                        (item: any, index: number) => {
                          UseImageUrl(
                            item?.node.mainImage,
                            item?.node._rawMainImage
                          );
                          return (
                            <SharedSlider
                              key={index}
                              sliderIndex={index}
                              sliderContainerClassName={styles.sliderItem}
                              sliderImage={
                                item?.node.mainImage.asset.gatsbyImageData
                              }
                              isAnimated
                              sliderDescriptionClassName={
                                styles.sliderDescription
                              }
                              sliderTitle={item?.node?.title}
                              publishedDate={item?.node?.publishedAt}
                              sliderDescription={item?.node?.subtitle}
                              link={item?.node?.title}
                              path="/news/"
                              readMore
                              alt={item?.alt}
                            />
                          );
                        }
                      )}
                    </Slider>
                  </CarouselProvider>
                )
              }
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CustomButton
                text={translate("ourstory-viewAll")}
                linkPath="/news"
                image={arrowRightWhite}
                buttonClass={styles.buttonMainClass}
              />
            </div>
          </div>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default OurStoryMain;

export const query = graphql`
  query {
    newsBlogs: allSanityBlog(
      filter: { blogType: { elemMatch: { title: { eq: "news" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          publishedAt
          blogType {
            title
          }
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allSanityAuthor(filter: { activeMember: { eq: "Yes" } }) {
      edges {
        node {
          _rawImage(resolveReferences: { maxDepth: 20 })
          id
          image {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          alt
          name
          position
          linkedin
          github
        }
      }
    }
  }
`;
