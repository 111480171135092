import React, { FC, ReactElement } from "react";

type Props = {
  children: ReactElement;
};
const SectionWrapper: FC<Props> = ({ children }) => {
  return <div style={{ padding: "40px 0" }}>{children}</div>;
};

export default SectionWrapper;
