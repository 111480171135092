import { useEffect, useState } from "react";

export const UseScrollPosition = () => {
  const [positionOfY, setPositionOfY] = useState<number>(() => {
    const initialState = 0;
    return initialState;
  });
  useEffect(() => {
    let mounted = true;
    window.addEventListener(
      "scroll",
      () => {
        if (mounted)
          setPositionOfY(
            () =>
              window.pageYOffset /
              (document.body.offsetHeight - window.innerHeight)
          );
      },
      false
    );
    return () => {
      mounted = false;
    };
  }, []);
  return positionOfY;
};
