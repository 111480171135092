import React, { useRef, FC } from "react";
import loadable from "@loadable/component";
import * as styles from "./SharedSlider.module.scss";
import greenArrowRightBlog from "../../../images/greenArrowRightBlog.svg";
import arrowGreenLeftBlog from "../../../images/arrowGreenLeftBlog.svg";
import { Link } from "gatsby";
import buttonRight from "../../../images/buttonArrowRight.svg";
import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import moment from "moment";
type Props = {
  data: any;
  path: any;
  haveLinks?: boolean;
  haveReadMore?: boolean;
  imagePath: string;
  rawImagePath: string;
  haveDate?: boolean;
};

const Carousel = loadable(() => import("react-tiny-slider"));

const SharedSliderMobile: FC<Props> = ({
  data,
  path,
  haveReadMore,
  imagePath,
  haveLinks,
  rawImagePath,
  haveDate,
}) => {
  const carouselBlog = useRef(null);
  const goNextSlide = () => carouselBlog.current.goTo("next");
  const goPreviousSlide = () => carouselBlog.current.goTo("prev");
  return (
    <div className={styles.mobSliderDiv}>
      <div style={{ zIndex: 5, position: "relative" }}>
        <div>
          <div className={styles.ArrowContainer}>
            <img
              src={arrowGreenLeftBlog}
              onClick={() => goPreviousSlide()}
              alt=""
            />
            <img
              src={greenArrowRightBlog}
              onClick={() => goNextSlide()}
              alt=""
            />
          </div>
        </div>
        <Carousel
          swipeAngle={false}
          items={1}
          mouseDrag
          center={true}
          loop={true}
          gutter={15}
          ref={carouselBlog}
          controls={false}
          nav={false}
          style={{ overflow: "hidden" }}
        >
          {Array.isArray(data) &&
            data?.map((item: any, index: any) => {
              UseImageUrl(item?.node[imagePath], item?.node[rawImagePath]);
              return (
                <div key={index}>
                  <div className={styles.blogImageContainer}>
                    <Link
                      to={`${path}${pathWithoutSpace(
                        item?.node?.title || item?.node?.name
                      )}`}
                    >
                      <img
                        src={
                          item?.node[imagePath].asset.gatsbyImageData.images
                            .fallback.src
                        }
                        alt={item?.node?.alt}
                        style={{ width: "100%" }}
                      />
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`${path}${pathWithoutSpace(
                        item?.node?.title || item?.node?.name
                      )}`}
                    >
                      {haveDate && (
                        <p className={styles.sliderDate}>
                          {" "}
                          {moment(item.node.publishedAt).format("MMM Do YYYY")}
                        </p>
                      )}
                      <h3> {item.node.title || item.node.name}</h3>
                      <p className={styles.designation}>
                        {item.node.subtitle || item.node.position}
                      </p>
                      {haveReadMore && (
                        <span>
                          <span
                            style={{
                              color: "#00ecbd",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                            }}
                          >
                            Read more
                          </span>
                          &nbsp;&nbsp;
                          <img src={buttonRight} alt="" />
                        </span>
                      )}
                    </Link>
                    {haveLinks && (
                      <div className={styles.authorLinkedIn}>
                        {item?.node?.github && (
                          <a
                            href={item?.node?.github}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.authorGithub}
                          >
                            <FaGithub color="#ffff" size={20} />
                          </a>
                        )}
                        {item?.node?.linkedin && (
                          <a
                            href={item?.node?.linkedin}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.authorGithub}
                          >
                            <FaLinkedinIn color="#ffff" size={22} />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};

export default SharedSliderMobile;
